import crypto from 'crypto-js';
const encryptionKey = '1128';
// encrypt
export const encryptObject = (object: any) => {
    const data = crypto.AES.encrypt(JSON.stringify(object), encryptionKey).toString();
    return data;
};

// Decrypt
export const decryptObject = (ciphertext: any) => {
    const bytes = crypto.AES.decrypt(ciphertext, encryptionKey);
    const decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
    return decryptedData;
};
