import React from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { ProfileOption } from './types';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { capitalize } from '../../../util/common';
import { useAuthentication } from '../../../hooks/userAuthentication';

const user =
    'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80';

type ProfileProps = {
    profileOptions: ProfileOption[];
};

const ProfileDropdown = ({ profileOptions }: ProfileProps) => {
    const currentUser = useSelector((state: any) => state.auth.user);

    return (
        <Dropdown as="li" className="nav-item">
            <Dropdown.Toggle as={Nav.Link} id="user">
                <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                        <img
                            src={currentUser?.profilePictureURL}
                            alt="user"
                            className="avatar avatar-xs rounded-circle me-2"
                        />
                    </div>
                    <div className="flex-grow-1 ms-1 lh-base">
                        <span className="fw-semibold fs-13 d-block line-height-normal">{currentUser?.firstName}</span>
                        <span className="text-muted fs-13">{capitalize(currentUser?.role)}</span>
                    </div>
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="p-2" renderOnMount>
                {(profileOptions || []).map((profile, index) => {
                    return (
                        <Nav.Item key={index.toString()}>
                            {index === profileOptions.length - 1 && <Dropdown.Divider as="div" />}
                            <NavLink to={profile.redirectTo} className="p-2">
                                <FeatherIcon icon={profile.icon} className="icon icon-xxs me-1 icon-dual" />
                                {profile.label}
                            </NavLink>
                        </Nav.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ProfileDropdown;
