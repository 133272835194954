import { useEffect } from 'react';

import AOS from 'aos';

// routes
import Routes from './routes/Routes';

// Themes
//  For Default import Theme.scss
import './assets/scss/theme.scss';
import { Provider } from 'react-redux';
import reduxStore from './redux/reduxStore';

const App = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <Provider store={reduxStore}>
            <Routes />
        </Provider>
    );
};

export default App;
