// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, doc } from 'firebase/firestore';
import { firebaseTable } from './firebaseTables';
import { getStorage } from 'firebase/storage';
import { getEnvironmentDetails } from './environmentConfig';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = getEnvironmentDetails().configFirebase;

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);

export const usersCollection = collection(db, firebaseTable.users);
export const vendorCollection = collection(db, firebaseTable.appointments_vendors);
export const categoriesCollection = collection(db, firebaseTable.appointments_categories);
export const appointmentBookingsCollection = collection(db, firebaseTable.appointments_bookings);

export const auth = getAuth(firebaseApp);

export const setIdToCollection = (id: string | undefined, tableName: string) => doc(db, tableName, id || '');
