import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

// images
import feature from 'assets/images/photos/3.jpg';
import customer from 'assets/images/mob/customer.jpeg';
import professional from 'assets/images/mob/professional.jpeg';
import vendor from 'assets/images/mob/vendor.jpeg';

export const appleLink = 'https://apps.apple.com/us/app/entirehub-service-near-me/id1614301024';
export const googleLink = 'https://play.google.com/store/apps/details?id=com.entirehub&hl=en_US&gl=US';

const Feature = () => {
    return (
        <section className="py-5 mb-xl-5 mb-lg-4 position-relative" data-aos="fade-up">
            <h1 className="display-3 fw-bold text-center">Who We Serve</h1>
            <Container>
                <Row className="align-items-center mt-5">
                    <Col lg={5}>
                        <h1 className="display-5 fw-semibold">Customer</h1>

                        <p className="text-muted my-4">
                            With over 150 service categories to choose from, you can book any service at your
                            fingertips. No more calling to inquire about their availability. You will be able to see
                            everything in one location.
                        </p>

                        <a href={appleLink} target="_blank" className="h6 text-primary">
                            Get an App
                            <FeatherIcon icon="arrow-right" className="ms-2 icon-xxs" />
                        </a>
                    </Col>
                    <Col lg={{ span: 6, offset: 1 }}>
                        <div className="img-content2 position-relative mt-4 mt-lg-0">
                            <div className="img-up mb-lg-0 mb-6">
                                <img src={customer} alt="feature" className="img-fluid d-block shadow" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="align-items-center mt-5">
                    <Col lg={{ span: 6 }}>
                        <div className="img-content2 position-relative mt-4 mt-lg-0">
                            <div className="img-up mb-lg-0 mb-6">
                                <img src={vendor} alt="feature" className="img-fluid d-block shadow" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <h1 className="display-5 fw-semibold">Vendors</h1>

                        <p className="text-muted my-2">
                            By offering services, you can reach new customers, market your business, and grow it. Get
                            booked, finish the job, and get paid. It's as simple as that.
                        </p>

                        <a href={appleLink} target="_blank" className="h6 text-primary">
                            Get an App
                            <FeatherIcon icon="arrow-right" className="ms-2 icon-xxs" />
                        </a>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="align-items-center mt-5">
                    <Col lg={5}>
                        <h1 className="display-5 fw-semibold">Professional</h1>

                        <p className="text-muted my-4">
                            No more wasting valuable talent; here is a place where you can show your abilities and work
                            full-time at your own pace. Create store, market and grow your business with us.
                        </p>

                        <a href={appleLink} target="_blank" className="h6 text-primary">
                            Get an App
                            <FeatherIcon icon="arrow-right" className="ms-2 icon-xxs" />
                        </a>
                    </Col>
                    <Col lg={{ span: 6, offset: 1 }}>
                        <div className="img-content2 position-relative mt-4 mt-lg-0">
                            <div className="img-up mb-lg-0 mb-6">
                                <img
                                    height="200px"
                                    src={professional}
                                    alt="feature"
                                    className="img-fluid d-block shadow"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Feature;
