import { Nav } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

const Menu = () => {
    return (
        <Nav as="ul" className="mx-auto">
            <Nav.Item as="li" className="pe-3">
                <NavLink to="/" className={classNames('nav-link', ({ ...isActive }) => isActive && 'active')}>
                    <div className="d-flex align-items-center">
                        <span className="icon-xxs me-1 flex-shrink-0">
                            <FeatherIcon icon="home" className="text-white" />
                        </span>
                        <div className="flex-grow-1">Home</div>
                    </div>
                </NavLink>
            </Nav.Item>

            <Nav.Item as="li" className="pe-3">
                <NavLink
                    to="/auth/appointments"
                    className={classNames('nav-link', ({ ...isActive }) => isActive && 'active')}
                >
                    <div className="d-flex align-items-center">
                        <span className="icon-xxs me-1 flex-shrink-0">
                            <FeatherIcon icon="calendar" className="text-white" />
                        </span>
                        <div className="flex-grow-1">Appointments</div>
                    </div>
                </NavLink>
            </Nav.Item>

            <Nav.Item as="li" className="pe-3">
                <NavLink
                    to="/auth/services"
                    className={classNames('nav-link', ({ ...isActive }) => isActive && 'active')}
                >
                    <div className="d-flex align-items-center">
                        <span className="icon-xxs me-1 flex-shrink-0">
                            <FeatherIcon icon="briefcase" className="text-white" />
                        </span>
                        <div className="flex-grow-1">Add Services</div>
                    </div>
                </NavLink>
            </Nav.Item>

            <Nav.Item as="li" className="pe-3">
                <NavLink
                    to="/auth/employee"
                    className={classNames('nav-link', ({ ...isActive }) => isActive && 'active')}
                >
                    <div className="d-flex align-items-center">
                        <span className="icon-xxs me-1 flex-shrink-0">
                            <FeatherIcon icon="users" className="text-white" />
                        </span>
                        <div className="flex-grow-1">Employee</div>
                    </div>
                </NavLink>
            </Nav.Item>
        </Nav>
    );
};

export default Menu;
