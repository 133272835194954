export const getEnvironmentDetails = () => {
    let configFirebase;
    let localFunctionApiUrl;
    let functionApiUrl;
    let meilisearchHostName;
    let meilisearchMasterKey;

    const isDevelopment = process.env.NODE_ENV === 'development';
    if (!isDevelopment) {
        meilisearchHostName = 'https://entirehub.net';
        meilisearchMasterKey = 'YTM5NWI2MTUxM2E1OWI0YjFkOWE4OWRh';
        configFirebase = {
            apiKey: 'AIzaSyCK3IV9EzobT4fl53xEfB7ZY7yZQe0Rs5A',
            authDomain: 'entirehub-prod.firebaseapp.com',
            projectId: 'entirehub-prod',
            storageBucket: 'entirehub-prod.appspot.com',
            messagingSenderId: '1081472584581',
            appId: '1:1081472584581:web:0ff1539649b264c6c252ec',
            measurementId: 'G-RW5R0VSNYY',
        };
        localFunctionApiUrl = 'http://localhost:5001/entirehub-prod/us-central1/app';
        functionApiUrl = 'https://entirehub.app';
    } else {
        meilisearchHostName = 'https://xtrach.com';
        meilisearchMasterKey = 'MWQxMWIxMWQ5YzhmZWIwOTgyZTJkMGE2';
        configFirebase = {
            apiKey: 'AIzaSyBrCB9z8Axw8Qzhpjfjp1jYwsAs9ldTnoo',
            authDomain: 'entirehub-dev.firebaseapp.com',
            projectId: 'entirehub-dev',
            storageBucket: 'entirehub-dev.appspot.com',
            messagingSenderId: '88274107911',
            appId: '1:88274107911:web:eea7f380a27f90dc21b4a2',
        };
        localFunctionApiUrl = 'http://localhost:5001/entirehub-dev/us-central1/app';
        functionApiUrl = 'https://us-central1-entirehub-dev.cloudfunctions.net/app';
    }
    return {
        functionApiUrl,
        localFunctionApiUrl,
        configFirebase,
        meilisearchHostName,
        meilisearchMasterKey
    };
};

export const API_URL = getEnvironmentDetails().functionApiUrl;
