// components
import { Navbar3 } from 'components/navbars';
import { Hero1 } from 'components/heros';
import BackToTop from 'components/BackToTop';

import Features1 from './Features1';
import Features2 from './Features2';
import Testimonials from './Testimonials';
import CTA from './CTA';
import Footer from './Footer';

// dummy data
import { features, features2 } from './data';
import SEO from './SEO';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/allReducers';
import { User } from '../../../interfaces/user';
import { Alert, Container } from 'react-bootstrap';

const App = () => {
    const currentUser = useSelector<AppState, User>((state: AppState) => state.auth.user);

    return (
        <>
            <SEO
                showDomain={false}
                title={'Entirehub - Service near me'}
                description={`One place where you can book, earn and live. With our mission of work is worth at is own place whether it's big or small`}
            />
            {!!currentUser && currentUser.role !== 'professional' && currentUser.role !== 'vendor' && (
                <section className="top-padding">
                    <Container>
                        <Alert variant={'warning'}>
                            Thank you for logging in as a user. Please use an app to find services in your area. In the future, we will also incorporate the user flow for the web.
                        </Alert>
                    </Container>
                </section>
            )}
            <section>
                <Hero1 />
            </section>

            {/* features */}
            <Features1 features={features} />

            {/* features2 */}
            <Features2 features2={features2} />

            {/* testimonials */}
            {/* <Testimonials /> */}

            {/* cta */}
            <CTA />
        </>
    );
};

export default App;
