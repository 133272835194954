import { Link } from 'react-router-dom';

// swiper
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// images
const saas1 =
    'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80';
const saas2 =
    'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80';
const saas3 =
    'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80';

interface IProps {
    photos?: string[];
    showPlayButton?: boolean;
}
const SwiperSlider3 = (props: IProps) => {
    const {photos, showPlayButton = true} = props;
    const swiperConfig = {
        slidesPerView: 1,
        loop: true,
        spaceBetween: 0,
        autoplay: {
            delay: 3000,
        },
        breakpoints: {
            '576': { slidesPerView: 1.2 },
            '768': { slidesPerView: 1 },
        },
        roundLengths: true,
    };

    return (
        <Swiper modules={[Autoplay]} {...swiperConfig}>
            {(photos || [saas1, saas2, saas3] || []).map((img, index) => {
                return (
                    <SwiperSlide key={index.toString()}>
                        <div className="swiper-slide-content ">
                            <div className="video-overlay d-flex align-items-center justify-content-center">
                                <Link to="#" className={showPlayButton ? "btn-play success" : ''}></Link>
                            </div>
                            <img src={img} alt="" className="img-fluid rounded-lg" />
                        </div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};

export default SwiperSlider3;
