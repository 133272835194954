import React, { useEffect, useState } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { auth } from '../config/firebaseConfig';
import { getUserByUserId } from '../config/Api/userApi';
import { logout, setUserData } from '../redux/auth';

export function useAuthentication() {
    const [user, setUser] = React.useState<User>();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        setLoading(true);
        const unsubscribeFromAuthStatusChanged = onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                setUser(user);
            } else {
                // User is signed out
                setUser(undefined);
                dispatch(logout());
                setLoading(false);
            }
        });

        return unsubscribeFromAuthStatusChanged;
    }, []);

    useEffect(() => {
        if (user?.uid) {
            getUserByUserId(user.uid, updateUserInfo);
        }
    }, [user?.uid]);

    const updateUserInfo = (data: any) => {
        dispatch(setUserData({ user: data }));
        setLoading(false);
    };

    return {
        user,
        loading,
    };
}
