import { User } from '../interfaces/user';

const UPDATE_USER = 'UPDATE_USER';
const LOG_OUT = 'LOG_OUT';

export const DUMMY_USER_DATA = null;

export interface AuthInitialState {
    user: User | any;
}

export const setUserData = (data: User | unknown) => ({
    type: UPDATE_USER,
    data,
});

export const logout = () => ({
    type: LOG_OUT,
});

const initialState: AuthInitialState = {
    user: DUMMY_USER_DATA,
};

export const auth = (state: AuthInitialState = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_USER:
            return {
                ...state,
                user: action.data.user,
            };
        case LOG_OUT: {
            return initialState;
        }
        default:
            return state;
    }
};
