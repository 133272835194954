import { Service } from '../Service';
import { API_URL } from '../environmentConfig';
import { Vendor } from '../../interfaces/vendor';

interface CustomerStripe {
    name: string;
    email: string;
    phone: string;
}

interface Customer {
    id: string;
    balance: number;
    created: number;
    currency?: string | null;
    description: string | null;
    email: string | null;
}

export const creatCustomerInStripe = async (body: CustomerStripe) => {
    try {
        const response = await Service.securePost(API_URL, '/wb-create-customer', body);
        return { ...(response as Customer), success: true };
    } catch (error) {
        return { success: false, id: null };
    }
};

export const createStripeConnectAccount = async (email: string, vendor: Vendor) => {
    const fullAddress = vendor?.address;
    const address = {
        postalCode: fullAddress?.postalCode,
        state: fullAddress?.state,
        city: fullAddress?.city,
        streetAddress: fullAddress?.streetAddress,
        streetAddress2: fullAddress?.streetAddress2 ?? '',
    };
    const mccCode = vendor?.businessInformation?.mccCode ?? '1520';

    const { succeeded, stripeConnectAccountId } = await Service.securePost(API_URL, '/create-stripe-connect-account', {
        email,
        address,
        mccCode,
        businessType: vendor?.businessType,
        businessName: vendor?.title ?? vendor?.name,
    });

    return { succeeded, stripeConnectAccountId };
};

export const createStripeConnectAccountLinkToUpdateInformation = async (accountId: string) => {
    const { succeeded, url } = await Service.securePost(API_URL, '/create-stripe-connect-account-link', {
        accountId,
    });

    return { succeeded, url };
};

export const checkStripeConnectAccountStatus = async (stripeAccountId: string) => {
    const { succeeded, requirements } = await Service.securePost(API_URL, '/get-stripe-connect-account-status', {
        stripeAccountId,
    });

    return { succeeded, requirements };
};

export const createStripeConnectAccountLoginLink = async (accountId: string) => {
    const { succeeded, url } = await Service.securePost(API_URL, '/create-stripe-connect-account-login', {
        accountId,
    });

    return { succeeded, url };
};

export const getConnectBalance = async (accountId: string) => {
    try {
        const response = await Service.securePost(API_URL, '/get-connect-balance', { accountId });
        return { ...response, success: true };
    } catch (error) {
        return { success: false, id: null };
    }
};
