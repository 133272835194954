import { doc, setDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { auth, setIdToCollection, usersCollection } from '../firebaseConfig';
import { firebaseTable } from '../firebaseTables';
import { createUserWithEmailAndPassword, sendPasswordResetEmail, updatePassword } from 'firebase/auth';
import { User } from '../../interfaces/user';
import { getUnixTimeStamp } from '../../util/timeAndDate';
import { creatCustomerInStripe } from './stripeApi';
import { meilisearchUpdateUserIndex } from './meilisearchApi';

export const userRef = doc(usersCollection);
export const setIdToUserCollection = (id: string | undefined) => setIdToCollection(id, firebaseTable.users);

export async function createUser(user: any) {
    await setDoc(setIdToUserCollection(user.id), user);
}

export const getUserByUserId = (userId: string, callback: Function) => {
    if (!userId) {
        return;
    }
    onSnapshot(setIdToUserCollection(userId), (res) => {
        if (res.exists()) {
            const user = res.data() as User;
            callback && callback(user);
            if (user.role === 'vendor' || user.role === 'professional') {
                meilisearchUpdateUserIndex(res.data() as User).then(r => r);
            }
        } else {
            callback && callback(null);
        }
    });
};

export const updateUserCollection = async (user: any) => {
    await updateDoc(setIdToUserCollection(user.id), user);
};

export const createAUserAccount = async (user: User) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, user.email, user.password);
        const id = userCredential.user.uid;
        const timeStamp = getUnixTimeStamp();
        const userData = {
            ...user,
            id,
            appIdentifier: 'rn-multi-vendor-appointments-android',
            profilePictureURL: 'https://www.iosapptemplates.com/wp-content/uploads/2019/06/empty-avatar.jpg',
            userID: id,
            createdAt: timeStamp,
            initialQuestion: true,
            lastLogin: timeStamp,
            lastOnlineTimestamp: timeStamp,
            password: '',
            isWeb: true,
        };
        await createUser(userData);
        return { success: true, id, error: null };
    } catch (error) {
        return { success: false, id: null, error };
    }
};

export const addStripeToTheUserAccount = async (user: User) => {
    const name = `${user.firstName} ${user.lastName}`;
    const stripe = await creatCustomerInStripe({ name, email: user.email, phone: user.phone });
    const userData = {
        id: user.id,
        stripeCustomerId: stripe.id ?? '',
    };
    await updateUserCollection(userData);
};

export const updateUserPassword = async (newPassword: string) => {
    try {
        const user = auth.currentUser;
        if (user) {
            await updatePassword(user, newPassword);
            return { succeed: true, error: null };
        }
    } catch (error: any) {
        return { succeed: false, error };
    }
};

export const sendPasswordReset = async (email: string) => {
    try {
        await sendPasswordResetEmail(auth, email);
        return { succeed: true, error: null };
    } catch (error) {
        return { succeed: false, error };
    }
};
