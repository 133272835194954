import { decryptObject, encryptObject } from '../pages/landings/App/common';
import { auth } from './firebaseConfig';

export class Service {
    static post = async (url: string, subUrl: string, body: Object) => {
        const tokenId = await auth.currentUser?.getIdToken();
        const response = await fetch(`${url}${subUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: tokenId ?? '',
            },
            body: JSON.stringify(body),
        });
        return await response.json();
    };

    static get = async (url: string, subUrl: string) => {
        try {
            const tokenId = await auth.currentUser?.getIdToken();
            const response = await fetch(`${url}${subUrl}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: tokenId ?? '',
                },
            });
            return await response.json();
        } catch (error) {
            return { error };
        }
    };

    static getFromAnyUrl = async (url: string) => {
        const response = await fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return await response.json();
    };

    static securePost = async (url: string, subUrl: string, body: Object) => {
        try {
            const tokenId = await auth.currentUser?.getIdToken();
            const response = await fetch(`${url}${subUrl}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: tokenId ?? '',
                },
                body: JSON.stringify({ data: encryptObject(body) }),
            });
            const { data, error } = await response.json();
            if (data) {
                return decryptObject(data);
            } else {
                return { error };
            }
        } catch (error) {
            return error;
        }
    };
}
