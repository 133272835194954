import { Nav, Dropdown, Row, Col } from 'react-bootstrap';
import { NavLink, Link, useLocation } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import classNames from 'classnames';

type MenuProps = {
    showDownload?: boolean;
    navClass?: string;
    buttonClass?: string;
    loggedInUser?: null;
};

const Menu = ({ navClass, buttonClass, showDownload, loggedInUser }: MenuProps) => {
    let location = useLocation();

    const isActiveRoute = (path: string) => {
        if (location.pathname) {
            return location.pathname.includes(path);
        }
        return false;
    };

    return (
        <Nav as="ul" className={classNames('align-items-lg-center', navClass)}>
            <Nav.Item as="li">
                <NavLink to="/" end className={classNames('nav-link', ({ ...isActive }) => isActive && 'active')}>
                    Home
                </NavLink>
            </Nav.Item>
            <Nav.Item as="li">
                <NavLink
                    to="/pages/about-us"
                    end
                    className={classNames('nav-link', ({ ...isActive }) => isActive && 'active')}
                >
                    About
                </NavLink>
            </Nav.Item>
            <Nav.Item as="li">
                <NavLink
                    to="/pages/contact"
                    end
                    className={classNames('nav-link', ({ ...isActive }) => isActive && 'active')}
                >
                    Contact
                </NavLink>
            </Nav.Item>
            <Nav.Item as="li">
                <NavLink
                    to="/pages/help"
                    end
                    className={classNames('nav-link', ({ ...isActive }) => isActive && 'active')}
                >
                    Help
                </NavLink>
            </Nav.Item>
            {loggedInUser && (<Nav.Item as="li">
                <NavLink
                    to="/auth/setting"
                    end
                    className={classNames('nav-link', ({ ...isActive }) => isActive && 'active')}
                >
                    Setting
                </NavLink>
            </Nav.Item>)}
        </Nav>
    );
};

export default Menu;
