import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

// swiper
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// images
import app1 from 'assets/images/feature/userHomePage.png';

// dummy data
import { slides } from './data';
import PaginationTab from 'pages/landings/App/PaginationTab';
import { useState } from 'react';
import { appleLink, googleLink } from 'pages/other/Company/Feature';
import google from '../../assets/images/buttons/google.png';
import store from '../../assets/images/buttons/store.png';

const SwiperSlider = () => {
    const swiperConfig = {
        breakpoints: {
            576: {
                slidesPerView: 1.2,
            },
            768: {
                slidesPerView: 1,
            },
        },
        roundLengths: true,
        slidesPerView: 1,
        loop: true,
        spaceBetween: 0,
        autoplay: {
            delay: 5000,
        },
    };

    return (
        <Swiper
            // install Swiper modules
            modules={[Autoplay]}
            {...swiperConfig}
        >
            {(slides || []).map((slide, index) => {
                return (
                    <SwiperSlide key={index.toString()}>
                        <div className="swiper-slide-content shadow bg-white rounded-sm p-3 quote">
                            <div className="d-flex text-start">
                                <img
                                    src={slide.avatar}
                                    alt=""
                                    className="img-fluid avatar-sm rounded-circle align-self-center me-3"
                                />
                                <div className="fs-14 text-muted">
                                    {slide.description}
                                    <p className="mb-0">
                                        <span className="ms-0">
                                            <FeatherIcon
                                                icon="star"
                                                className="me-1 icon icon-xxs icon-fill-warning text-warning"
                                            />
                                            <FeatherIcon
                                                icon="star"
                                                className="me-1 icon icon-xxs icon-fill-warning text-warning"
                                            />
                                            <FeatherIcon
                                                icon="star"
                                                className="me-1 icon icon-xxs icon-fill-warning text-warning"
                                            />
                                            <FeatherIcon
                                                icon="star"
                                                className="me-1 icon icon-xxs icon-fill-warning text-warning"
                                            />
                                            <FeatherIcon
                                                icon="star"
                                                className="me-1 icon icon-xxs icon-fill-warning text-warning"
                                            />
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};

const Hero1 = () => {
    const [isVendorOpen, setVendorOpen] = useState(true);
    const [isProfessionalOpen, setProfessionalOpen] = useState(false);
    const [isCustomerOpen, setCustomerOpen] = useState(false);

    const userJsx = (
        <>
            <h1 className="hero-title">
                The best way to book <span className="highlight highlight-warning d-inline-block">Appointments</span>{' '}
                from your Mobile App
            </h1>
            <p className="mt-3 fs-17 text-muted">
                Fast, secure and reliable way to find professionals of your need near you in seconds. Download the App.
            </p>
        </>
    );

    const professionalJsx = (
        <>
            <h1 className="hero-title">
                Opportunity to <span className="highlight highlight-warning d-inline-block">start</span> the career path
            </h1>
            <p className="mt-3 fs-17 text-muted">
                No business, no worries, we got you covered. Sign up as a vendor and start working at your own pace.
                Let's show them all your TALENT. Download the App.
            </p>
        </>
    );

    const vendorJsx = (
        <>
            <h1 className="hero-title">
                Work, <span className="highlight highlight-warning d-inline-block">Earn,</span> and Live.
            </h1>
            <p className="mt-3 fs-17 text-muted">
                Add business, show what you offer, accept the booking, and get paid—easy and secure flow from the
                beginning to the end. Download the App.
            </p>
        </>
    );

    const imageNdSwipperJsx = (imageLocation: any, showSwipper: boolean = true) => {
        return (
            <Col lg={{ offset: 2, span: 4 }} className="text-end">
                <div className="position-relative">
                    <div className="hero-img mt-4 mt-sm-0">
                        <img src={imageLocation} alt="" className="img-fluid" data-bs-aos="zoom-in-up" />
                    </div>
                    {showSwipper && (
                        <div className="slider">
                            <SwiperSlider />
                        </div>
                    )}
                </div>
            </Col>
        );
    };

    return (
        <section className="position-relative hero-1 pt-7 pt-sm-6 pb-5">
            <Container className="hero-container">
                <Row className="text-center text-md-start">
                    <Col lg={6} className="pt-2 pt-sm-3">
                        <PaginationTab
                            setCustomerOpen={setCustomerOpen}
                            setProfessionalOpen={setProfessionalOpen}
                            setVendorOpen={setVendorOpen}
                        />
                        {isCustomerOpen && userJsx}
                        {isProfessionalOpen && professionalJsx}
                        {isVendorOpen && vendorJsx}

                        <div>
                            <a href={googleLink} className="d-block d-sm-inline-flex">
                                <img src={google} alt="" height="52" />
                            </a>
                            <a href={appleLink} className="d-block d-sm-inline-flex mt-2 mt-sm-0 ms-0 ms-sm-2">
                                <img src={store} alt="" height="52" />
                            </a>
                        </div>
                        <div className="pt-3 pt-sm-5 d-flex align-items-center action-buttons">
                            {/*<a*/}
                            {/*    target="_blank"*/}
                            {/*    href={appleLink}*/}
                            {/*    className="btn btn-primary"*/}
                            {/*    data-bs-toggle="smooth-scroll"*/}
                            {/*>*/}
                            {/*    Download App*/}
                            {/*</a>*/}

                            {/* <div className="ms-3">
                                <Link className="text-primary d-flex align-items-center" to="#">
                                    <Button className="btn-soft-primary btn-rounded-circle btn-icon me-2 shadow-none">
                                        <FeatherIcon
                                            className="icon-xxs icon-dual-primary align-self-center"
                                            icon="play"
                                        />
                                    </Button>
                                    <span className="fw-semibold">Watch Video</span>
                                </Link>
                            </div> */}
                        </div>
                    </Col>
                    {isCustomerOpen && imageNdSwipperJsx(require('../../assets/images/features/userHomePage.png'))}
                    {isProfessionalOpen &&
                        imageNdSwipperJsx(require('../../assets/images/features/category.png'), false)}
                    {isVendorOpen &&
                        imageNdSwipperJsx(require('../../assets/images/features/bookAppointment.png'), false)}
                </Row>
            </Container>
        </section>
    );
};

export default Hero1;
