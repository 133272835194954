import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// components
import Root from './Root';
import PrivateRoute from './PrivateRoute';
import App from '../pages/landings/App';
import { useAuthentication } from '../hooks/userAuthentication';
import { array } from 'yup';
import { useSelector } from 'react-redux';
import { AppState } from '../redux/allReducers';
import { User } from '../interfaces/user';
import NotFoundPage from '../components/NotFoundPage';

// 404NotFound
const NotFound = React.lazy(() => import('../components/NotFoundPage'));
// lazy load all the views
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const SignUp = React.lazy(() => import('../pages/auth/SignUp'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

//vendor
const VendorStore = React.lazy(() => import('../pages/Vendor/Store/VendorStore'));
const EditVendor = React.lazy(() => import('../pages/Vendor/EditVendor'));
const Services = React.lazy(() => import('../pages/Vendor/Services/Services'));
const Employee = React.lazy(() => import('../pages/Vendor/Employee/Employee'));
const Appointments = React.lazy(() => import('../pages/other/account/Dashboard/Appointments'));

// home
const Home = React.lazy(() => import('../pages/landings/App'));

//common
const PrivacyPolicy = React.lazy(() => import('../pages/landings/App/PrivacyPolicy'));
const TermsOfService = React.lazy(() => import('../pages/landings/App/TermsOfService'));
const CookiePolicy = React.lazy(() => import('../pages/landings/App/CookiePolicy'));
const AcceptableUsePolicy = React.lazy(() => import('../pages/landings/App/AcceptableUsePolicy'));
const Verified = React.lazy(() => import('../pages/landings/App/Verified'));
const Post = React.lazy(() => import('../pages/landings/App/Post'));
const FeesAndRefund = React.lazy(() => import('../pages/landings/App/FeesAndRefund'));
const AppStore = React.lazy(() => import('../pages/landings/App/Appstore'));

// landings
// const App = React.lazy(() => import('../pages/landings/App'));
const Saas = React.lazy(() => import('../pages/landings/Saas/Saas'));
const Saas2 = React.lazy(() => import('../pages/landings/Saas/Saas2'));
const Startup = React.lazy(() => import('../pages/landings/Startup'));
const Software = React.lazy(() => import('../pages/landings/Software'));

const Agency = React.lazy(() => import('../pages/landings/Agency'));
const Coworking = React.lazy(() => import('../pages/landings/Coworking'));
const Crypto = React.lazy(() => import('../pages/landings/Crypto'));
const Marketing = React.lazy(() => import('../pages/landings/Marketing'));
const Portfolio = React.lazy(() => import('../pages/landings/Portfolio'));

// pages
const Dashboard = React.lazy(() => import('../pages/other/account/Dashboard'));
const Settings = React.lazy(() => import('../pages/other/account/Settings'));

const Blog = React.lazy(() => import('../pages/other/Blog'));
const BlogPost = React.lazy(() => import('../pages/other/BlogPost'));

const Company = React.lazy(() => import('../pages/other/Company'));
const Contact = React.lazy(() => import('../pages/other/Contact'));
const Career = React.lazy(() => import('../pages/other/Career'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));

const PortfolioGrid = React.lazy(() => import('../pages/other/portfolio/PortfolioGrid'));
const PortfolioMasonry = React.lazy(() => import('../pages/other/portfolio/PortfolioMasonry'));
const PortfolioItem = React.lazy(() => import('../pages/other/portfolio/PortfolioItem'));

const HelpDesk = React.lazy(() => import('../pages/other/HelpDesk'));

// docs
const Introduction = React.lazy(() => import('../pages/docs/Introduction'));
const QuickStart = React.lazy(() => import('../pages/docs/QuickStart'));
const Customization = React.lazy(() => import('../pages/docs/Customization'));
const Routing = React.lazy(() => import('../pages/docs/Routing'));
const CodeSpliting = React.lazy(() => import('../pages/docs/CodeSpliting'));
const ChangeLog = React.lazy(() => import('../pages/docs/ChangeLog'));

const Colors = React.lazy(() => import('../pages/docs/Colors'));
const Typography = React.lazy(() => import('../pages/docs/Typography'));

const Bootstrap = React.lazy(() => import('../pages/docs/Bootstrap'));
const Custom = React.lazy(() => import('../pages/docs/Custom'));
const Plugins = React.lazy(() => import('../pages/docs/Plugins'));

const Navbars = React.lazy(() => import('../pages/docs/Navbars'));
const Heros = React.lazy(() => import('../pages/docs/Heros'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

interface IProps {
    user: any;
}

const AllRoutes = ({ user }: IProps) => {
    const currentUser = useSelector<AppState, User>((state: AppState) => state.auth.user);
    const toComponent = (component: React.LazyExoticComponent<() => JSX.Element>, forVendor: boolean = true) => {
        if (currentUser && currentUser.role === 'vendor' && forVendor) {
            return component;
        }
        else if (user && !forVendor) {
            return component;
        } else {
            return Login;
        }
    }
    const commonRoute = [
        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
        { path: 'logout', element: <LoadComponent component={Logout} /> },
        { path: 'setting', element: <LoadComponent component={toComponent(Settings, false)} /> },
    ];
    const differentPath: Array<any> = [
        ...commonRoute,
        { path: 'edit-vendor', element: <LoadComponent component={toComponent(EditVendor)} /> },
        { path: 'appointments', element: <LoadComponent component={toComponent(Appointments)} /> },
        { path: 'services', element: <LoadComponent component={toComponent(Services)} /> },
        { path: 'employee', element: <LoadComponent component={toComponent(Employee)} /> },
    ];
    
    return useRoutes([
        {
            // root route
            path: '/',
            element: currentUser && currentUser.role === 'vendor' ? <LoadComponent component={Dashboard} /> : <App />,
        },
        {
            path: '*',
            element: <LoadComponent component={NotFound} />
        },
        {
            // public routes
            path: '/',
            children: [
                {
                    path: 'condition',
                    children: [
                        { path: 'privacy-policy', element: <LoadComponent component={PrivacyPolicy} /> },
                        { path: 'terms-of-service', element: <LoadComponent component={TermsOfService} /> },
                        { path: 'cookie-policy', element: <LoadComponent component={CookiePolicy} /> },
                        { path: 'acceptable-use-policy', element: <LoadComponent component={AcceptableUsePolicy} /> },
                        { path: 'verified', element: <LoadComponent component={Verified} /> },
                    ],
                },
                {
                    path: 'landing',
                    children: [
                        // { path: 'app', element: <LoadComponent component={App} /> },
                        { path: 'saas', element: <LoadComponent component={Saas} /> },
                        { path: 'saas2', element: <LoadComponent component={Saas2} /> },
                        { path: 'startup', element: <LoadComponent component={Startup} /> },
                        { path: 'software', element: <LoadComponent component={Software} /> },

                        { path: 'agency', element: <LoadComponent component={Agency} /> },
                        { path: 'coworking', element: <LoadComponent component={Coworking} /> },
                        { path: 'crypto', element: <LoadComponent component={Crypto} /> },
                        { path: 'marketing', element: <LoadComponent component={Marketing} /> },
                        { path: 'portfolio', element: <LoadComponent component={Portfolio} /> },
                    ],
                },
                { path: 'store/:vendorId', element: <LoadComponent component={VendorStore} /> },
                { path: 'post/:title', element: <LoadComponent component={Post} /> },
                { path: 'appstore', element: <LoadComponent component={AppStore} /> },
                {
                    path: 'pages',
                    children: [
                        { path: 'contact', element: <LoadComponent component={Contact} /> },
                        { path: 'career', element: <LoadComponent component={Career} /> },
                        { path: 'about-us', element: <LoadComponent component={Company} /> },
                        { path: 'help', element: <LoadComponent component={HelpDesk} /> },
                        { path: 'fees-refund', element: <LoadComponent component={FeesAndRefund} /> },
                    ],
                },
                {
                    path: 'auth',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'signup', element: <LoadComponent component={SignUp} /> },
                        ...differentPath,
                    ],
                },
            ],
        },

    ]);
};

export default AllRoutes;
