import { API_URL, getEnvironmentDetails } from "config/environmentConfig";
import { Service } from "config/Service";
import { User } from "interfaces/user";
import { Vendor } from "interfaces/vendor";

export const meilisearchUpdateVendorIndex = async (body: Vendor) => {
    try {
        const response = await Service.securePost(API_URL, '/update-vendor-index', body);
        return response;
    } catch (error) {
        return { success: false, id: null };
    }
}

export const meilisearchUpdateUserIndex = async (body: User) => {
    try {
        const response = await Service.securePost(API_URL, '/update-user-index', body);
        return response;
    } catch (error) {
        return { success: false, id: null };
    }
}

export const meilisearchGetVendorByVendorId = async (vendorId: string) => {
    try {
        const response = await Service.securePost(API_URL, '/get-store', {vendorId});
        return response;
    } catch (error) {
        return { success: false, id: null };
    }
}

export const searchVendor = async (text: string) => {
    try {
        const response = await Service.securePost(API_URL, '/search', {text});
        return response;
    } catch (error) {
        return { success: false, id: null };
    }
}
