// types
import { Feature, Feature2 } from './types';

import book from 'assets/images/mob/book.png';
import confirm from 'assets/images/mob/confirm.png';
import orderDetails from 'assets/images/mob/orderDetails.png';
import payment from 'assets/images/mob/payment.png';

const features: Feature[] = [
    {
        icon: 'book',
        variant: 'primary',
        title: 'Easy booking',
        description: 'Book a professional serivce within minutes with our easy booking system.',
    },
    {
        icon: 'shield',
        variant: 'success',
        title: 'Secure Messaging',
        description: 'Conversation between you and the professional is secured with end to end encryption.',
    },
    {
        icon: 'sliders',
        variant: 'orange',
        title: 'Categories',
        description:
            'With over 150 service categories to choose from, you will be able to find the professional that is right for you.',
    },
    {
        icon: 'bell',
        variant: 'info',
        title: 'Notifications/Emails',
        description: 'Up-to-date notifications and emails.',
    },
];

const features2: Feature2[] = [
    {
        image: book,
        shapes: ['shape1', 'shape2'],
        title: 'Book an Appointment',
        description: 'Once user book an appointment, vendor or professional will be notified. ',
    },
    {
        image: confirm,
        shapes: ['shape3', 'shape4'],
        title: 'Confirm the Appointment',
        description:
            'Professionals or vendors can review the appointment request and confirm that all information is correct.',
    },
    {
        image: orderDetails,
        shapes: ['shape5', 'shape6'],
        title: 'Complete an appointment',
        description:
            'Once an appointment is confirmed or completed, more information will appear on the Booking details page, where you can find every step of your appointment detail.',
    },
    {
        image: payment,
        shapes: ['shape5', 'shape6'],
        title: 'Earn money',
        description: 'Vendor would be paid on the scheduled date once the appointment is completed.',
    },
];

export { features, features2 };
