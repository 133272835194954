import { UseFormReturn } from 'react-hook-form/dist/types';

export const getFullName = (user: any) => {
    return `${user?.firstName} ${user?.lastName}`;
};

export const capitalize = (word: string) => {
    if (word) {
        return word[0].toUpperCase() + word.substring(1);
    }
    return '';
};

export const setDefaultValues = (defaultValues: any, method: UseFormReturn<any, any>) => {
    const { setValue, clearErrors } = method;
    Object.keys(defaultValues).forEach((item) => {
        setValue(item, defaultValues[item]);
        clearErrors && clearErrors(item);
    });
};

export const clearForm = (defaultValues: any, method: UseFormReturn<any, any>) => {
    const { setValue, clearErrors } = method;
    Object.keys(defaultValues).forEach((item) => {
        setValue(item, '');
        clearErrors && clearErrors(item);
    });
};
