const img1 =
    'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80';
const img2 =
    'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80';

interface Slide {
    avatar: string;
    description: string;
}

const slides: Slide[] = [
    {
        avatar: img1,
        description: 'This app is blessing for all professionals!',
    },
    {
        avatar: img2,
        description: 'Very convenient to use project manager!',
    },
];

export { slides };
