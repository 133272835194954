import BackToTop from 'components/BackToTop';
import { Navbar4 } from 'components/navbars';
import Footer from 'pages/landings/App/Footer';
import { BrowserRouter } from 'react-router-dom';

// routes
import AllRoutes from '.';
import { useAuthentication } from '../hooks/userAuthentication';
import { useSelector } from 'react-redux';
import Fragment from '../components/Fragment';
import Navbar2 from '../components/navbars/Navbar2';

const Routes = () => {
    const { user, loading } = useAuthentication();
    const currentUser = useSelector((state: any) => state.auth.user);
    const header = () => {
        if (user && currentUser?.role === 'vendor') {
            return <Navbar4 navClass="navbar-dark" fixedWidth />;
        } else {
            return (
                <Navbar2
                    hideSearch={false}
                    user={user}
                    navClass="navbar-dark"
                    fixedWidth
                    buttonClass="btn-orange btn-sm"
                />
            );
        }
    };

    return (
        <Fragment loading={loading}>
            <BrowserRouter>
                {header()}
                <AllRoutes user={user} />
                <Footer />
                <BackToTop />
            </BrowserRouter>
        </Fragment>
    );
};

export default Routes;
