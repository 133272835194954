import { Container, Spinner } from 'react-bootstrap';

interface IProps {
    loading?: boolean;
    children?: any;
}

export default function Fragment({ loading = false, children }: IProps) {
    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <Spinner animation="border" className="mt-3 m-5" variant={'red'}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    } else {
        return children;
    }
}
