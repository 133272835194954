import React from 'react';
import HelmetExport from 'react-helmet';

interface Props {
    title: string;
    description: string;
    showDomain?: boolean;
}
function SEO({ title, description, showDomain = true }: Props) {
    return (
        <HelmetExport>
            {showDomain ? <title>{`${title} | Entirehub.com`}</title> : <title>{`${title}`}</title>}
            <meta name="description" content={`${description}`} />
        </HelmetExport>
    );
}

export default SEO;
