import { AnyAction, combineReducers } from 'redux';
import { auth, AuthInitialState } from './auth';

export interface AppState {
    auth: AuthInitialState;
}

const allReducers = combineReducers<AppState>({
    auth,
});

const AppReducer = (state: any | undefined, action: AnyAction) => {
    if (action.type === 'LOG_OUT') {
        state = undefined;
    }
    return allReducers(state, action);
};

export default AppReducer;
