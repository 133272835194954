import moment from 'moment';

export const get24HoursTimeWithInterval = (
    interval: number = 30,
    startHour: number = 0,
    endHour: number = 24,
    timeToPrepareForNextWork: number = 0,
    bookedTime: string[] = [],
    lunchTime: string[] = [],
    applyLunchTime: boolean = true
) => {
    let startTime = moment(startHour, 'h:mm A');
    const endTime = moment(endHour, 'h:mm A');
    const lunchStart = moment(lunchTime[0], 'h:mm A');
    const lunchEnd = moment(lunchTime[1], 'h:mm A');

    let times = [];
    if (applyLunchTime && lunchTime.length > 0) {
        while (startTime < lunchStart) {
            if (!isInBreak(startTime, bookedTime)) {
                times.push(startTime.format('h:mm A'));
            }
            startTime.add(interval + timeToPrepareForNextWork, 'minutes');
        }
        times = times.slice(0, -1);
        while (lunchEnd < endTime) {
            if (!isInBreak(lunchEnd, bookedTime)) {
                times.push(moment(lunchEnd).format('h:mm A'));
            }
            lunchEnd.add(interval + timeToPrepareForNextWork, 'minutes');
        }
    } else {
        while (startTime < endTime) {
            if (!isInBreak(startTime, bookedTime)) {
                times.push(startTime.format('h:mm A'));
            }
            startTime = startTime.add(interval + timeToPrepareForNextWork, 'minutes');
        }
    }
    return times;
};

function isInBreak(slotTime: any, breakTimes: string[]) {
    return breakTimes.some((br) => {
        return slotTime >= moment(br[0], 'h:mm A') && slotTime < moment(br[1], 'h:mm A');
    });
}

export const get60MinutesTime = () => {
    const minutes = [];
    for (let i = 0; i < 60; i++) {
        minutes.push(i);
    }
    return minutes;
};

export const get12hoursTime = () => {
    const hours = [];
    for (let i = 0; i < 12; i++) {
        hours.push(i);
    }
    return hours;
};

export const map12HoursToLabelAndValue = get12hoursTime().map((item) => ({ label: item, value: item }));
export const map60MinutesToLabelAndValue = get60MinutesTime().map((item) => ({ label: item, value: item }));

export const getStartAndEndTime = get24HoursTimeWithInterval().map((item: any, index: number) => ({ label: item, value: item, id: index === 0 ? 0 : Number(index / 2), }));

export const daysList = [
    { id: 1, value: 'Monday', label: 'Monday' },
    { id: 2, value: 'Tuesday', label: 'Tuesday' },
    { id: 3, value: 'Wednesday', label: 'Wednesday' },
    { id: 4, value: 'Thursday', label: 'Thursday' },
    { id: 5, value: 'Friday', label: 'Friday' },
    { id: 6, value: 'Saturday', label: 'Saturday' },
    { id: 0, value: 'Sunday', label: 'Sunday' },
];

export const calculateTimeAndReturnsInMinutes = (hour = 0, minutes = 0) => {
    if (!!hour && hour === 0) {
        return Number(minutes);
    } else {
        return Number(Number(hour) * 60 + Number(minutes));
    }
};

let timeOutId: any;
export const debounce = (func: any, delay: number) => {
    return (...args: any) => {
        if (timeOutId) {
            clearTimeout(timeOutId);
        }
        timeOutId = setTimeout(() => {
            func.apply(null, args);
        }, delay);
    };
};
